import { ScheduleProps } from '@augustine-institute/lib';

export const schedule: ScheduleProps[] = [
  {
    time: '9:00 am MST',
    title:
      'Welcome and Opening Prayer with Dr. Tim Gray, President of the Augustine Institute',
    // desc: 'The Academic Dean of the Graduate School will kick off the event by introducing St. Augustine of Hippo, explaining why the Doctor of Grace was chosen as the Institute’s patron saint. Dr. Innerst will also discuss Augustine’s correlation between the Memory, Intellect, and Will and the theological virtues of faith, hope, and love.',
  },
  {
    time: '9:05 am MST',
    subtime: 'Dr. Sean Innerst',
    title: '“There remains a Sabbath rest for the people of God” Heb 4:9',
    excerpt: 'Those of us in ministry can be eaten alive by our to-do lists.',
    desc: (
      <>
        <p>Those of us in ministry can be eaten alive by our to-do lists.</p>
        <p>
          This presentation by Dr. Innerst will be part theological, and part
          practical, suggesting that the biblical imperative for Sabbaths is
          also being addressed by Jesus when He says to Martha, “you are anxious
          and troubled by many things; one thing is needful” (Lk 10:41).
        </p>
        <p>
          There are practical ways to bring simplicity and unification to our
          work in the Church.
        </p>
        <p>
          We can find ways to “recollect” the “many things” into the “one thing
          needful” by introducing some economies into the way that we approach
          our busy lives of Christian service.
        </p>
      </>
    ),
  },
  {
    time: '9:45 am MST',
    subtime: 'Prof. Lucas Pollice',
    title:
      'The Ancient Ministry: “That which we have seen and heard we also proclaim to you.”  1 John 1:3',
    excerpt:
      'Pope Francis recently re-instituted the ancient ministry of catechist.',
    desc: (
      <>
        <p>
          Pope Francis recently re-instituted the ancient ministry of catechist.
          In so doing, he has highlighted the importance of the ministry of
          catechist in the life of the Church.
        </p>
        <p>
          Drawing from the new{' '}
          <strong>
            <em>Directory for Catechesis</em>
          </strong>
          , this presentation will explore the ministry of catechist by
          analyzing the urgent need for an authentic renewal of catechesis in
          our time, the crucial and indispensable role of the catechist, and how
          to form catechists to be effective instruments of evangelization and
          catechesis.
        </p>
      </>
    ),
  },
  {
    time: '10:30 am MST',
    title: 'Break',
  },
  {
    time: '10:45 am MST',
    subtime: 'Dr. Gwen Adams',
    title:
      'Challenges and Opportunities for Lay Leaders: Findings from the 2021 Survey',
    excerpt:
      'This talk will share key findings from a survey of lay leaders conducted this Fall.',
    desc: (
      <>
        <p>
          This talk will share key findings from a survey of lay leaders
          conducted this Fall.
        </p>
        <p>
          Challenges and opportunities facing today's lay leaders in the church
          will be discussed in light of these findings as well as earlier
          presentations from Drs. Pollice and Innerst.
        </p>
      </>
    ),
  },
  {
    time: '11:30 am MST',
    title: 'Panel Discussion',
    desc: <p>With Profs. Sean Innerst, Lucas Pollice, and Gwen Adams.</p>,
  },
  {
    time: '12:00 pm MST',
    title: 'Concluding Remarks and Closing Prayer',
  },
];
