import { StaticImage } from 'gatsby-plugin-image';
import { RiCalendar2Fill, RiTimeLine } from 'react-icons/ri';
import { HeadProps } from 'gatsby';
import {
  Tabs,
  TabList,
  Tab,
  TabPanelWrapper,
  TabPanel,
  Schedule,
  Speakers,
} from '@augustine-institute/lib';
import SEO from '../components/Seo';
import Bonus from '../components/Bonus';
import Form from '../components/2022-02/Form';
import { EVENT_START } from '../utils/2022-02/config';
import EventImage from '../components/2022-02/EventImage';
import { schedule } from '../data/2022-02/schedule';
import { speakers } from '../data/2022-02/speakers';

function EventTitle({ className = '' }: { className?: string }) {
  return (
    <div className={`event-title ${className}`}>
      <a
        href="https://www.augustineinstitute.org/graduate-school/graduate-school"
        className="logo"
      >
        <StaticImage
          src="../images/logo-full.png"
          alt="Augustine Institute"
          loading="eager"
          objectFit="contain"
          placeholder="none"
        />
      </a>

      <h1>
        “Come Away and Rest Awhile”{' '}
        <span
          style={{
            display: 'inline-block',
            fontSize: '50%',
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          - Mark 6:31
        </span>
      </h1>
      <h2 className="h4">A Virtual Conference for Lay Leaders</h2>

      <div className="event-details">
        <ul>
          <li>
            <RiCalendar2Fill title="date" />
            <h3 className="h6">Saturday, February 12, 2022</h3>
          </li>
          <li>
            <RiTimeLine title="time" />
            <h3 className="h6">9:00 am – 12:00 pm MST</h3>
          </li>
        </ul>
      </div>
    </div>
  );
}

function EventExtraText({ className = '' }: { className?: string }) {
  return (
    <>
      <p className={`event-extra-text ${className}`}>
        This Conference for Lay Leaders will also serve to announce our new
        Master of Arts in Pastoral Theology, with an optional Concentration in
        Catechesis, which will be made available both on-campus and online to
        Catholic leaders around the world.
      </p>
      <p className={`event-extra-text ${className}`}>
        Tune in and join us as we seek to strengthen you in your roles as lay
        leaders!
      </p>
    </>
  );
}

export default function HomePage() {
  const eventStart = EVENT_START;
  const today = new Date();
  const msToEvent = eventStart.valueOf() - today.valueOf();
  const daysToEvent = Math.floor(msToEvent / (24 * 60 * 60 * 1000));

  const daysToEventString = `${daysToEvent} ${
    daysToEvent > 1 ? 'days' : 'day'
  }`;

  return (
    <main className="page-2022-02">
      <section className="container page-m-t section-m-b">
        <div className="row">
          <div className="col-xs-12 hide-xs show-sm hide-md">
            <EventTitle className="sm-hide md-show" />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-7">
            <EventTitle className="hide-sm show-md" />
            <div className="event-desc">
              <div className="text-columns">
                <EventImage className="hide-sm" />
                <p>
                  If you are a lay leader serving in a parish, diocese, or other
                  Catholic apostolate, join Augustine Institute faculty for a
                  virtual conference to inform and refresh you for the important
                  work you do.
                </p>
                <p>
                  You will hear from our experienced faculty as they address
                  topics such as:
                </p>
                <ul className="bullets">
                  <li>
                    how to better use the tools God has provided and reduce
                    stress,
                  </li>
                  <li>
                    what the Church is telling us with the revival of the
                    ancient ministry of the Catechist, and
                  </li>
                  <li>
                    how the study of theology and effective pastoral practice
                    contribute to fruitful Church ministry.
                  </li>
                </ul>
                <p>
                  There will also be a panel discussion of faculty with answers
                  to your questions.
                </p>
                <EventExtraText />
                <div className="event-img__placeholder" />
              </div>
              <Bonus img={<EventImage />} />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-5">
            <div className="registration">
              <div className="registration__inner">
                <div className="registration__dots hide-xs show-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 90 230"
                  >
                    <circle cx="25" cy="25" r="5" fill="#fff" />
                    <circle cx="25" cy="145" r="5" fill="#fff" />
                    <circle cx="65" cy="145" r="5" fill="#fff" />
                    <circle cx="45" cy="25" r="5" fill="#fff" />
                    <circle cx="45" cy="145" r="5" fill="#fff" />
                    <circle cx="85" cy="145" r="5" fill="#fff" />
                    <circle cx="25" cy="45" r="5" fill="#fff" />
                    <circle cx="25" cy="165" r="5" fill="#fff" />
                    <circle cx="65" cy="165" r="5" fill="#fff" />
                    <circle cx="45" cy="45" r="5" fill="#fff" />
                    <circle cx="45" cy="165" r="5" fill="#fff" />
                    <circle cx="85" cy="165" r="5" fill="#fff" />
                    <circle cx="25" cy="65" r="5" fill="#fff" />
                    <circle cx="25" cy="185" r="5" fill="#fff" />
                    <circle cx="65" cy="185" r="5" fill="#fff" />
                    <circle cx="45" cy="65" r="5" fill="#fff" />
                    <circle cx="45" cy="185" r="5" fill="#fff" />
                    <circle cx="85" cy="185" r="5" fill="#fff" />
                    <circle cx="25" cy="85" r="5" fill="#fff" />
                    <circle cx="25" cy="205" r="5" fill="#fff" />
                    <circle cx="65" cy="85" r="5" fill="#fff" />
                    <circle cx="65" cy="205" r="5" fill="#fff" />
                    <circle cx="45" cy="85" r="5" fill="#fff" />
                    <circle cx="45" cy="205" r="5" fill="#fff" />
                    <circle cx="85" cy="85" r="5" fill="#fff" />
                    <circle cx="85" cy="205" r="5" fill="#fff" />
                    <circle cx="25" cy="105" r="5" fill="#fff" />
                    <circle cx="25" cy="225" r="5" fill="#fff" />
                    <circle cx="65" cy="105" r="5" fill="#fff" />
                    <circle cx="65" cy="225" r="5" fill="#fff" />
                    <circle cx="45" cy="105" r="5" fill="#fff" />
                    <circle cx="45" cy="225" r="5" fill="#fff" />
                    <circle cx="85" cy="105" r="5" fill="#fff" />
                    <circle cx="85" cy="225" r="5" fill="#fff" />
                    <circle cx="25" cy="125" r="5" fill="#fff" />
                    <circle cx="65" cy="125" r="5" fill="#fff" />
                    <circle cx="45" cy="125" r="5" fill="#fff" />
                    <circle cx="85" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="5" r="5" fill="#fff" />
                    <circle cx="5" cy="125" r="5" fill="#fff" />
                    <circle cx="5" cy="25" r="5" fill="#fff" />
                    <circle cx="5" cy="145" r="5" fill="#fff" />
                    <circle cx="5" cy="45" r="5" fill="#fff" />
                    <circle cx="5" cy="165" r="5" fill="#fff" />
                    <circle cx="5" cy="65" r="5" fill="#fff" />
                    <circle cx="5" cy="185" r="5" fill="#fff" />
                    <circle cx="5" cy="85" r="5" fill="#fff" />
                    <circle cx="5" cy="205" r="5" fill="#fff" />
                    <circle cx="5" cy="105" r="5" fill="#fff" />
                  </svg>
                </div>
                {(daysToEvent >= 0 && (
                  <>
                    <div className="registration__title">
                      <h2 className="h5" style={{ marginBottom: '0.25em' }}>
                        Event begins in
                      </h2>
                      <h3 className="h2">{daysToEventString}</h3>
                    </div>
                    <div className="registration__form">
                      <Form />
                    </div>
                  </>
                )) || (
                  <div className="registration__title">
                    <h3 className="h2">Event has ended</h3>
                    <h2 className="h5">Thank you for your participation!</h2>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12">
            <EventExtraText className="event-extra-text--bottom" />
          </div>
        </div>
      </section>
      <section className="container section-m-b">
        <Tabs id="courses">
          <TabList>
            <Tab id="event-schedule">
              <span className="h3">Event Schedule</span>
            </Tab>
            <Tab id="course-of-study2">
              <span className="h3">Speakers</span>
            </Tab>
          </TabList>
          <TabPanelWrapper>
            <TabPanel>
              <p className="narrow">
                This livestream event will start at{' '}
                <strong>9:00 am MST on Saturday, February 12, 2022.</strong>
              </p>
              <Schedule schedule={schedule} />
            </TabPanel>
            <TabPanel>
              <Speakers speakers={speakers} />
            </TabPanel>
          </TabPanelWrapper>
        </Tabs>
      </section>
    </main>
  );
}

export function Head({ location: { pathname } }: HeadProps) {
  return (
    <SEO
      title="Come Away and Rest Awhile - Virtual Conference for Lay Leaders"
      description="If you are a lay leader serving in a parish, diocese, or other Catholic apostolate, join Augustine Institute faculty for a virtual conference to inform and refresh you for the important work you do."
      pathname={pathname}
      image="/2022-02/social-card.jpg"
    />
  );
}
