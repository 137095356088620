import { SpeakerProps } from '@augustine-institute/lib';
import { StaticImage } from 'gatsby-plugin-image';

export const speakers: SpeakerProps[] = [
  {
    name: 'Dr. Sean Innerst',
    title: 'Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/sean-innerst.jpg"
        alt="Dr. Sean Innerst"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Sean Innerst has been teaching theology, evangelization, and catechesis
        to seminarians, college students, missionaries, graduate students, DREs,
        deacon candidates, and catechists for over twenty-five years. Formerly
        DRE for the Diocese of Rapid City, he helped found two important
        Catholic institutions in Denver: St. John Vianney Theological Seminary,
        as its founding Provost, and the Augustine Institute, as its founding
        Academic Dean. Sean is also the originator and instructor for the
        popular two-year “Ladder of Ascent” program on the Catechism of the
        Catholic Church in the Catechetical School of the Archdiocese of Denver,
        and now in other dioceses in the U.S.
      </p>
    ),
  },
  {
    name: 'Professor Lucas Pollice ',
    title:
      'Associate Professor of Theology and Catechetics | Director, MA in Leadership for the New Evangelization Degree Program',
    image: (
      <StaticImage
        src="../../images/speakers/lucas-pollice.jpg"
        alt="Professor Lucas Pollice "
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Professor Pollice is an Associate Professor of Theology and Catechetics
        and Director of the MA in Leadership for the New Evangelization Degree
        Program. He has over twenty years of extensive leadership, ministry, and
        teaching experience in the areas of catechetics and pastoral theology at
        both the parish and diocesan levels. Professor Pollice was awarded the
        Benemerenti Medal by Pope Benedict XVI for outstanding service to the
        Church in 2011. He completed his Master’s in Theological Studies from
        St. Mary’s College of Ave Maria University.
      </p>
    ),
  },
  {
    name: 'Dr. Gwen Adams',
    title: 'Visiting Assistant Professor of Theology',
    image: (
      <StaticImage
        src="../../images/speakers/gwen-adams.jpg"
        alt="Dr. Gwen Adams"
        layout="fullWidth"
      />
    ),
    bio: (
      <p>
        Dr. Adams is a teacher and writer, working in the intersection of faith
        and culture, with emphasis on parish life, education, and theater. She
        has served in a number of apostolic and academic endeavors, including
        positions as diocesan marriage tribunal advocate, youth minister, farm
        intern, mentor of Catholic undergraduate households, and teacher on the
        middle-school, high-school, undergraduate, and graduate levels. She is
        the founder of{' '}
        <a
          href="https://www.bardstreet.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bardstreet.com
        </a>
        , offering new plays and youth and young adult theater.
      </p>
    ),
  },
];
